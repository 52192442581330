import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokins_extended_contract_rfs" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Защитник национальной сборной Латвии и футбольного клуба РФС (Рига) Владиславс Сорокинс продлил контракт с клубом ещё на один год. Поздравляем!</p>
        </div>
      </div>
    )
  }
}